.list-project-container {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .line-home-container {
    position: absolute;
    padding: 0 40px;
    height: 100%;
    mix-blend-mode: difference;

    .line-home-box {
      width: 100%;
      height: 100%;

      .div-parent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .col-home-content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .col-home-x {
          position: absolute;
          width: calc(100% - 20px);
          height: 100%;
        }

        .col-home-y {
          position: absolute;
          width: 100%;
          height: calc(100% - 20px);
        }
      }
    }
  }

  .header-list-project {
    width: 100%;
    // font-family: "Arial", monospace;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    // padding-left: calc(40px + 4%);
    padding-bottom: var(--padding-vertical);
    padding-top: var(--padding-vertical);
    mix-blend-mode: difference;

    .title-experiments {
      font-family: "BebasNeue Regular", monospace;
      letter-spacing: 0.1vw;
      font-weight: 700;
    }

    .description-experiments {
      font-family: "Roboto Mono Local", monospace;
      font-weight: 700;
      margin-top: -2vh;
    }
  }

  .list-project-bg {
    padding: 0 40px;

    // .col-item-project {
    // }

    .card-box-container {
      height: 100%;
      display: flex;
      flex-direction: row;
      font-family: 'Roboto Mono', monospace;
      position: relative;

      .overlay {
        position: absolute;
        padding: 9px;
        width: 100%;
        z-index: 101;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .overlay-bg,
        .project-button {

          position: inherit;
          opacity: 1;
          width: calc(100% - 18px);
          height: calc(100% - 18px);
          border: none;
          transition: opacity 0.3s ease;
        }

        // .overlay-bg {
        //   border-radius: 5px;
        //   background-color: lightgray; //#f5f5f5
        // }

        .project-button {
          cursor: pointer;
          // border: 1px solid;
          // font-size: 12px;
          font-weight: 600;
          font-family: 'Roboto Mono', monospace;
          background: none;
        }
      }

      // Previous Implementation
      // .centered-button {
      //   top: 50%;
      //   left: 50%;
      //   min-width: 150px;
      //   transform: translate(-50%, -50%);
      //   padding: 7px 20px;
      //   position: absolute;
      //   border-radius: 5px;
      //   border: none;
      //   cursor: pointer;
      //   font-size: 12px;
      //   font-weight: 600;
      //   opacity: 1;
      //   font-family: 'Roboto Mono', monospace;
      //   z-index: 101;
      //   border: 1px solid black;
      // }



      .card-item-container {
        margin: 40px;
        border-radius: 15px;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        position: relative;

        .image-project-box {
          z-index: 100;
          cursor: pointer;
          width: 100%;
          height: 100%;
          
          .video-player {
            width: 100%;
            height: auto;
            // transition: filter 0.5s ease, transform 0.5s ease;
            animation: none;
          }

          .project-button {
            cursor: pointer;
            font-weight: 700;
            position: relative;
            bottom: calc(50% - 4.5px);
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(80% - 18px);
            // height: calc(20% - 18px);
            height: 15%;
            // border: 1px solid;
            font-family: 'Roboto Mono Local', monospace;
            border: none;
            background: none;
          }

          .button-line {
            height: 100%;
            width: 100%;
            background-color: #f5f5f5;
            position: absolute;
            top: 0;
            animation: button-pan 0.8s ease-in-out;
          }
      
          @keyframes button-pan {
      
            0%,
            10% {
              width: 0%;
            }
      
            50%,
            100% {
              width: 100%;
            }
          }
        }

        .image-project {
          border-radius: 10px;
        }

        .card-item-right {
          padding: 10px 25px;
          overflow-y: scroll;
        }

        .text-client {
          font-weight: 600;
        }

        .text-services {
          color: #4D4D4D;
          margin-top: 5px;
        }

        .project-title {
          font-weight: 700;
          margin: 5px 0 15px 0;
        }

        .text-services-value {
          margin-top: 3px;
        }
      }
    }
  }
}

.list-project-container::-webkit-scrollbar {
  display: none;
}