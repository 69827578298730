@keyframes blink-caret {
  from, to {
      border-color: transparent;
  }
  50% {
      border-color: var(--bs-body-color);
  }
}

.sider-left {
  // position: fixed;
  left: 40px;
  mix-blend-mode: difference;
  border-right: 1px solid white;

  .content-left-box {
    border-bottom: 1px solid white;
    margin-right: 40px;
    font-family: "Roboto Mono Local", monospace;
    height: calc(100% / 3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .box-left-content {
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: center;
      //padding-top: 14px;

      .text-loading-left {
        margin: 0;
        padding: 0;
        min-height: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        letter-spacing: 0;
      }
    }
    .box-left-content::-webkit-scrollbar {
      display: none;
    }
  }

  .typewriter-container.pause{
    border-color: var(--bs-body-color);
    animation: none;
  }

  .typewriter-container {
    border-right: .5em solid; /* The cursor */
    animation: blink-caret 0.75s step-end infinite;
  }
}

.text-gray {
  color: gray;
  margin: 10px 0;
}

.span-text-loading {
  animation: StrobeEffect 0.2s linear infinite;
  font-weight: bold;
  color: gray !important;
  margin-top: 0;
}
