.experimentation-container {
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow-y: hidden;

  .text-title {
    height: calc(100%);
    width: 100%;
    font-family: "Arial", monospace;
    position: absolute;
    font-weight: bold;
    letter-spacing: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    // z-index: 102;
    padding-left: 8%;

    p {
      line-height: 100%;
    }
  }

  .table-container {
    position: absolute;
    height: 100%;

    .td-table-container {
      position: relative;
      padding: 10px;
      width: calc(100% / 4);
      pointer-events: none;

      .img-gif-base {
        height: 100%;
        border-radius: 10px;
        cursor: pointer;
        pointer-events: all;
      }

      .img-gif-first {
        @extend .img-gif-base;
        width: 100%;
      }

      .img-gif-first2 {
        @extend .img-gif-base;
        width: calc(200% + 20px);
      }

      .img-gif-first3 {
        @extend .img-gif-base;
        width: calc(100% - 20px);
        position: absolute;
        top: 10px;
      }

      .img-gif-first4 {
        @extend .img-gif-base;
        width: calc(200% - 20px);
        position: absolute;
        top: 10px;
      }
    }
  }

  table {
    border-collapse: collapse;
    padding: 0;
  }

  table,
  tbody {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  tr {
    margin: 0;
    padding: 0;
    height: calc(100% / 3);
  }

  .line-home-container {
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 0 40px;
    z-index: -1;
    mix-blend-mode: difference;

    .line-home-box {
      width: 100%;
      height: 100%;
      z-index: -10;

      .div-parent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .col-home-content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .col-home-x {
          position: absolute;
          width: calc(100% - 20px);
          height: 100%;
        }

        .col-home-y {
          position: absolute;
          width: 100%;
          height: calc(100% - 20px);
        }
      }
    }
  }

}