.footer-mobile {
  background: none;
  width: 100vw;
  margin: 0;
  padding: 0;
  bottom: 0;
  position: absolute;
  height: 15vh;
}

.footer-mobile-border {
  border-top: 1px solid white;
  mix-blend-mode: difference;
}

.footer-mobile-container {
  // z-index: 1;
  // height: 15%;
  height: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  // z-index: 100;
  font-weight: bold;
}

.footer-intro-mobile {
  padding: 0 4%;
  // z-index: 500;
  font-size: 2.6vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .audio-indicator-mobile {
    z-index: 1;
    font-family: "Roboto Mono", monospace;
    font-size: 2vmin !important;
    font-weight: 700;
    background-color: rgba(255, 255, 255, 0.75);
    color: black;
    border-radius: 50%;
    height: 9.52vmin;
    padding-top: 0.5vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: normal;
    aspect-ratio: 1;
    -webkit-user-select: none !important;
    -webkit-touch-callout: none !important;
    -webkit-tap-highlight-color: transparent !important;

    .audio-icon {
      display: block;
      color: black;
      text-align: center;
    }
  }

  .text-footer-mobile {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;

    p {
      width: 100%;
    }
  }
  
  .footer-mid {
    width: 100%;
  }

  .footer-button {
    z-index: 1;
    width: 35%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none !important;
    -webkit-touch-callout: none !important;
    -webkit-tap-highlight-color: transparent !important;
  }

  .icon-transfer {
    // mix-blend-mode: difference;
    font-size: 3.4vmin;
    // font-size: 20px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.75);
    color: black;
    padding: 3.06vmin;
    border-radius: 50%;
    -webkit-user-select: none !important;
    -webkit-touch-callout: none !important;
    -webkit-tap-highlight-color: transparent !important;
  }

  .footer-mobile-arrows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 1;

    .swipe-up {
      position: absolute;
      width: 60%;
      height: 100%;
    }
  }

  .footer-mobile-arrows span {
    display: block;
    width: 2vw;
    height: 2vw;
    transform: rotate(45deg);
    border-top: solid 1.5px;
    border-left: solid 1.5px;
    border-color: var(--footer-color, white);
    animation: swipe-up 1.5s infinite;
    margin-bottom: -1vw;
  }

  .footer-mobile-arrows span:nth-child(1) {
    animation-delay: -0.4s;
  }

  .footer-mobile-arrows span:nth-child(2) {
    animation-delay: -0.2s;
  }

  @keyframes swipe-up {
    0% {
      opacity: 0;
      transform: rotate(45deg) translate(0vw, 0vw);
    }

    40%,
    60% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      transform: rotate(45deg) translate(-4vw, -4vw);
    }
  }

  .text-view-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1px solid white;
    font-size: 2.6vw;
    width: 40vw;
    padding: 6px;
    color: var(--footer-color, white);
    text-shadow: 0 0 1px white;
    // background-color: white;
  }

  .icon-warning {
    margin-right: 5px;
    font-size: 20px;
  }

  .text-intro-footer {
    width: 100%;
    text-align: center;
    margin-top: 2%;
  }
}