.project-mobile-detail {
  font-family: "Roboto Mono", monospace;
  overflow-y: auto;
  color: white;

  .project-image {
    width: 100%;
    height: auto;
    margin-top: 10px;
    padding: 10px;
  }

  .project-mobile-detail-footer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-top: 1px solid white;
    overflow-y: scroll;

    .image-mobile-footer {
      width: 50px;
      height: 50px;
      filter: invert(1);
    }

    .button-back-to-top {
      font-size: 10px;
      font-family: "Roboto Mono", monospace;
      font-weight: 700;
      border: none;
      background: none;
      cursor: pointer;
      color: white;
      z-index: 2;

      .icon-flash-end {
        animation: StrobeEffect 0.3s infinite;
      }
    }
  }

  .end-project-item {
    margin-top: 10px;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .icon-warning {
      margin-right: 5px;
      font-size: 21px;
    }


  }

  .project-description-container {
    padding: 10px;
    margin-top: 10px;
  }

  .text-experiment {
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
  }

  .text-name-project {
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    margin: 15px 0;
    font-family: "BebasNeue Regular", monospace;
  }
  .text-description-project {
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
  }
}
