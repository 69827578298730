.project-detail-container {
  margin: 0 40px;
  overflow-y: auto;
  font-family: "Roboto Mono Local", monospace;

  .project-detail-container-border {
    position: absolute;
    // border: 1px solid white;
    mix-blend-mode: difference;
    pointer-events: none;
  }

  .project-client-title {
    margin-top: 15px;
    font-weight: 700;
    font-family: "Roboto Mono Local", monospace;
  }

  .project-client-title-safari {
    margin-top: 15px;
    font-weight: 500;
    font-family: "Roboto Mono Local", monospace;
  }

  .project-detail-description {
    font-weight: 700;
    margin-bottom: 30px;
    width: 100%;

    .text-gray {
      margin: 20px 0;
    }
  }

  .detail_description-project {
    font-family: "Roboto Mono Local", monospace;
    margin-top: 3px;
  }

  .project-detail-info-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    mix-blend-mode: difference;
  }

  .project-detail-info-container2 {
    font-family: "Roboto Mono Local", monospace;
    font-weight: 400;
    width: 100%;
    padding: 0 40px;
    display: flex;
    flex-direction: row;

    .info-left {
      width: 60%;
      padding-right: 50px;
    }

    .info-right {
      width: 40%;
    }


    .project-client {
      margin-top: 3px;
      font-weight: 400;
    }

    .text-gray {
      font-size: 12px;
    }

    .footer {
      margin-top: 15px;
    }
  }

  .text-title-container-detail {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Arial", monospace;
    width: 100%;
    overflow: hidden;
    padding: 0 40px;
    margin-top: 25px;

    .text-project-title {
      margin: 7px 0;
      color: #B2B2B2;
      font-family: "Roboto Mono Local", monospace;
    }
  }

  .text-title-detail {
    font-weight: bold;
    font-family: "BebasNeue Regular", monospace;
    margin-bottom: 0;
  }

  .description-detail {
    font-weight: 700;
    line-height: 15px;
    display: flex;
    flex-direction: row;

    .left {
      width: 50%;
    }

    .right {
      width: 50%;
    }
  }

  .image-container {
    margin-top: 10px;

    .images-project {
      width: 100%;
      height: auto;
    }
  }
}

.video-project {
  margin-top: 10px;
  width: 100%;
  height: auto;
  mix-blend-mode: normal !important;
}

@media only screen and (max-width: 1500px) {
  .text-title-detail {
    font-size: 60px;
  }
}

.project-detail-container::-webkit-scrollbar {
  display: none;
}