.home-intro-mobile {
  width: 100%;
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto Mono", monospace;
  font-size: 2.6vw;
  line-height: 3.2vw;
  font-weight: 700;
  overflow-y: scroll;
  color: white;

  .header-slide-container {
    position: absolute;
    height: calc(var(--window-height) - 117vw);
    width: 60%;
    top: 0;
    margin-left: 5%;
    animation: fadeInContent 0.5s ease-in-out;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1vh 0;

    .text-title-mobile,
    .text-detail-mobile {
      font-weight: normal;
      font-size: 2.6vw;
      word-spacing: 0;
      letter-spacing: 0;
      margin-bottom: 5px;
      font-family: "Roboto Mono Local", monospace;
    }

    .text-title-desc-mobile {
      font-size: 2.6vw;
      word-spacing: 0;
      letter-spacing: 0;
      margin-bottom: 15px;
      font-family: "Roboto Mono Local", monospace;
    }

    .text-detail-desc-mobile {
      font-weight: 700;
      font-size: 10vw;
      line-height: 10vw;
      margin-bottom: 5px;
      font-family: "BebasNeue Regular", monospace;
    }
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }
}

.div-loading-container {
  width: 100%;
  height: 100%;

  .loading-first-mobile {
    padding: 0 12%;
    height: 85%;
    bottom: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .span-text-loading {
      margin-right: 40px;
    }

  }
}

.intro-container-mobile {
  text-align: center;
  height: 85%;
  padding: 0 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .description-intro-mobile {
    font-size: 2.6vw;
    line-height: 3.2vw;
  }

  .typewriter-container.pause {
    border-color: var(--bs-body-color);
    animation: none;
  }

  .typewriter-container {
    border-right: .5em solid;
    /* The cursor */
    animation: blink-caret 0.75s step-end infinite;
  }

  .image-group-container {
    position: relative;
    width: 100%;

    .image-home-intro {
      width: 100%;
      padding: 25%;
      height: auto;
      margin-top: 15%;
      left: 0;
      top: -40%;
      position: absolute;
      filter: invert(1);
    }

    .text-start-intro {
      width: 100%;
      position: absolute;
      top: 0;
      text-align: center;
      animation: StrobeEffect1 0.3s linear infinite;
    }

    .text-end-intro {
      width: 100%;
      bottom: 0;
      text-align: center;
      position: absolute;
      margin-bottom: 5px;
      animation: StrobeEffect1 0.3s linear infinite;
    }

    @keyframes StrobeEffect1 {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    .image__circle-home-intro {
      width: 90%;
      height: auto;
      padding: 10%;
      filter: invert(1);
    }
  }

  .text-gray-carrot {
    color: gray;
    margin: 5vh 0;
  }
}

.intro-container-mobile2 {
  text-align: center;
  height: 85%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .description-intro-mobile {
    font-size: 2.6vw;
    line-height: 3.2vw;
  }

  .image-group-container {
    position: relative;
    width: 100%;

    .image-home-intro {
      width: 100%;
      padding: 25%;
      height: auto;
      margin-top: 15%;
      left: 0;
      top: -40%;
      position: absolute;
      filter: invert(1);
    }

    .text-start-intro {
      width: 100%;
      position: absolute;
      top: 0;
      text-align: center;
      animation: StrobeEffect1 0.3s linear infinite;
    }

    .text-end-intro {
      width: 100%;
      bottom: 0;
      text-align: center;
      position: absolute;
      margin-bottom: 5px;
      animation: StrobeEffect1 0.3s linear infinite;
    }

    @keyframes StrobeEffect1 {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    .image__circle-home-intro {
      width: 90%;
      height: auto;
      padding: 10%;
      filter: invert(1);
    }
  }
}

.intro-container {
  text-align: center;
  position: absolute;
  height: 85%;
  padding: 0 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 15%;

  .button-right {
    border: 1px solid white;
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 15px;
    padding-top: 15px;
    border-radius: 0;
    height: auto;
    font-family: "Roboto Mono", monospace;
    display: inline-block;
    overflow: hidden;
    background: white;
    color: black;
    font-size: calc(0.8vw);
    font-weight: 700;
    margin-left: 40px;
    width: calc(100% - 40px);
    text-transform: uppercase;
  }

  .image-group-container {
    position: relative;
    width: 100%;

    .image-home-intro {
      width: 100%;
      padding: 25%;
      height: auto;
      margin-top: 15%;
      left: 0;
      top: -40%;
      position: absolute;
      filter: invert(1);
    }

    .text-start-intro {
      width: 100%;
      position: absolute;
      top: 0;
      text-align: center;
      animation: StrobeEffect1 0.3s linear infinite;
    }

    .text-end-intro {
      width: 100%;
      bottom: 0;
      text-align: center;
      position: absolute;
      margin-bottom: 5px;
      animation: StrobeEffect1 0.3s linear infinite;
    }

    @keyframes StrobeEffect1 {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    .image__circle-home-intro {
      width: 90%;
      height: auto;
      padding: 10%;
      filter: invert(1);
    }
  }
}

.box-line-container {
  width: 10%;
  right: 0;
  border-left: 2px solid black;
  position: absolute;
  height: 100%;
}


.lottie-player-container {
  height: 100%;
  width: 100%;
}

.title-mobile {
  top: 0;
  width: 100%;
  opacity: 100%;
  pointer-events: all;

  #app {
    position: relative;
    z-index: 10;
    height: 100dvh;
    max-height: 100dvh;
  }

  .container {
    max-height: 100%;
    height: 100%;
    box-sizing: border-box;

    .wrapper {
      // transform: translateY(var(--offset-from-top));
      height: 100%;
      max-height: 100%;
      padding: 0vmax;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;

      .video-container {
        overflow: scroll;
        display: inline-block;
        height: 100%;
        width: 100%;

        .title-video-player {
          object-fit: cover;
          opacity: 0;
          display: block;
        }
      }
    }
  }

  #webgl {
    position: fixed;
    z-index: 0;
    inset: unset;
    top: 0;
    opacity: 1;
    transition: opacity 0.3s ease;
    cursor: none;
    // display: none;
  }
}