.project-list-home {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .swipe-up{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

.footer-intro-mobile {
  // border-top: 2px solid black;
  position: absolute;
  bottom: 0;
  height: 15%;
  width: 100%;
  padding: 0 8%;

  .text-footer-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .icon-transfer {
    font-size: 20px;
    cursor: pointer;
    z-index: 1;
    background-color: black;
    color: white;
    padding: 18px;
    border-radius: 50%;
  }

  .text-create-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid white;
    font-size: 2.6vw;
    width: 40vw;
    padding: 6px;
  }

  .icon-warning {
    margin-right: 5px;
    font-size: 20px;
  }
}

.projects-list-mobile-container {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.project-mobile-container {
  width: 100%;
  height: 100vw;
  position: relative;
  cursor: pointer;
  text-align: center;
  font-family: "Roboto Mono", monospace;

  .project-mobile-border {
    border-top: 1px solid white;
  }

  .button-container {
    position: absolute;
    width: 100%;
    bottom: 10px;
    // text-align: center;

    .button-view-detail {
      font-family: "Roboto Mono", monospace;
      background-color: white;
      color: black;
      padding: 7px;
      border: none;
      font-size: 2.6vw;
      font-weight: 700;
      width: 40vw;
    }
  }

  .index-project {
    position: absolute;
    bottom: 15px;
    right: 10px;
    font-size: 25px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  }

  .project-name {
    font-size: 7vw;
    font-weight: 700;
    line-height: 47px;
    text-transform: uppercase;
  }

  .image-project-mobile {
    position: absolute;
    width: 100%;
    border: none;
    height: 100vw;
    opacity: 1; // 0.7
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}