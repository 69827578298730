// @import '../../index.scss';

.mobile-container {
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  background-color: #0a0a0a;
  background-image: url("./../../assets/gif/DIRT.gif");
  background-size: 100%;

  .header-mobile {
    background: none;
    margin: 0;
    padding: 0;
    width: 100vw;
    
    .header-mobile-border {
      position: relative;
      border-bottom: 1px solid;
      // mix-blend-mode: difference;
      z-index: 1;
    }
  }
  
  .content-mobile {
    width: 100%;
    height: 90%;
    margin: 0;
    padding: 0;
    background: none;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
  }
  
  .header-mobile-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    z-index: 500;
    height: 100%;
    padding: 0 10px;
    position: relative;
    mix-blend-mode: difference;
    
    .logo-mobile-container {
      filter: invert(1);
      width: 25%;
      height: 100%;
      padding: 5px;

      .logo-mobile {
        width: 100%;
        height: auto;
      }
    }

    .title-header {
      font-family: "Roboto Mono", monospace;
      font-size: 10px;
      line-height: 15px;
      font-weight: 700;
      margin-left: -4%;
    }
  }
}

.mobile-container::-webkit-scrollbar {
  display: none;
}
